import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@services/auth.service';
import { decryptionString } from '@shared/utils/encryption-string';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private auth = inject(AuthenticationService);
  private router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // Query parameter from browser extension for shared authentication between extension and Console
    const extensionToken: string = route.queryParams?.ahTkn || '';

    if (extensionToken) {
      AuthenticationService.setToken(decryptionString(extensionToken));
      this.router.navigate([location.pathname]);
    }
    const isAuth: boolean = await this.auth.isAuthenticated();
    if (!isAuth) {
      this.auth.logout(state.url);
    }
    return isAuth;
  }
}
